import { ResponsiveSpacing } from "@/components";

export enum AnimStage {
    INITIAL = 1,
    WMDETAILS = 2,
    WMMERGING = 3,
    WMMERGED = 4,
    // WMBITS = 2,
    // WMDETAILS = 3,
    // WMINITIAL2 = 4,
    // WMMERGED = 5,
}
export const NUM_ANIM_STAGE = Object.keys(AnimStage).length;

export const MIN_ANIM_SIZE_PX = 160;
export const MAX_ANIM_SIZE_PX = 1000;
export const MAX_CONTENT_WIDTH_PX = 1200;
export const SCROLL_DIV_HEIGHT = '200dvh';

export const getResponsiveComponentPositions = (animSizePx: number) => {
    const m = animSizePx / 300;
    const ret = {
        top: {
            t: { initial: -50 * m, hover: -150 * m, merged: 50 * m, centered: 0 },
        },
        bottom: {
            t: { initial: 50 * m, exitX: 1400 * m, exitY: 2000 * m, merged: 50 * m, centered: 0 },
        },
        parent: {
            t: { appX: -180 },
            rotate: { X: 51, Z: 43 },
        },
    }
    return ret;
};

export const SCROLL_POSITIONS: Record<AnimStage | 'scroll', number> = {
    [AnimStage.INITIAL]: 0.01,
    [AnimStage.WMDETAILS]: 0.2,
    [AnimStage.WMMERGING]: 0.3,
    [AnimStage.WMMERGED]: 0.45,
    scroll: 0.5
};

export const getPointOnLine = (pointA: number, pointB: number, ratio: number) => {
    return (pointB - pointA) * ratio + pointA;
}

export const COMPACT_VIEW_WIDTH_PX = 720;
export const EXTRA_COMPACT_VIEW_WIDTH_PX = 420;
export const getPageMarginLeftRight = (compact?: boolean, pageWidthPx?: number) => {
    const DEFAULT_MARGIN = ResponsiveSpacing.space6;
    const DEFAULT_MARGIN_COMPACT = ResponsiveSpacing.space5;
    const marginLeftRight = compact ?
        DEFAULT_MARGIN_COMPACT :
        `calc(max(${((pageWidthPx || 0) - MAX_CONTENT_WIDTH_PX) / 2}px, ${DEFAULT_MARGIN}))`
    return marginLeftRight;
}