import { Box } from '@mui/material';
import { motion, useTransform, easeInOut, MotionValue } from 'framer-motion';
import image_square_2 from '@assets/image_square_2.jpg';
import { useMemo } from 'react';
import { AnimStage, SCROLL_POSITIONS, getPointOnLine, getResponsiveComponentPositions } from '../types-and-constants';
import DetailsContent from './details-content';
import { ZIndexes } from '@/components';

type AnimProps = {
    scrollYProgress: MotionValue<number>,
    initialAnimationComplete: boolean,
    setInitialAnimationComplete: () => void,
    animSizePx: number,
    animRightMargin?: string,
}

export default function Anim({ scrollYProgress, initialAnimationComplete, setInitialAnimationComplete, animSizePx, animRightMargin }: AnimProps) {

    const c = getResponsiveComponentPositions(animSizePx);
    const s = SCROLL_POSITIONS;

    const borderRadiusPx = animSizePx / 8;

    const APPEAR_AND_ROTATE_DURATION = 0.8;
    const SEPARATION_DURATION_LONG = 1;
    const SEPARATION_DURATION_SHORT = 1;

    // INITIAL ANIMATIONS ---------------------------------------------------------------------

    const INITIAL_ANIM_SHOW_DETAILS = false;

    const initialAnimations = useMemo(() => ({
        parent: {
            animate: { rotateX: [0, 0, 51], rotateY: [0, 0, 0], rotateZ: [0, 0, 43], translateZ: 0, opacity: [0, 1, 1] },
            transition: {
                duration: APPEAR_AND_ROTATE_DURATION,
                times: [0, 0.2, 1], // times range from 0 to 1 (then scaled to duration)
                delay: 0,
                ease: easeInOut,
            },
            initial: { opacity: 0 },
        },
        top: {
            animate: { opacity: 0.5, translateX: c.top.t.initial, translateY: c.top.t.initial },
            transition: {
                duration: INITIAL_ANIM_SHOW_DETAILS ? SEPARATION_DURATION_LONG : SEPARATION_DURATION_SHORT,
                delay: APPEAR_AND_ROTATE_DURATION,
                ease: easeInOut,
            },
            initial: { opacity: 0 },
        },
        topOverlayDetails: {
            animate: INITIAL_ANIM_SHOW_DETAILS ? { opacity: [0, 0, 1, 0], translateX: c.top.t.initial, translateY: c.top.t.initial } : {},
            transition: INITIAL_ANIM_SHOW_DETAILS ? {
                duration: INITIAL_ANIM_SHOW_DETAILS ? SEPARATION_DURATION_LONG : SEPARATION_DURATION_SHORT,
                times: [0.2, 0.5, 0.7, 1],
                delay: APPEAR_AND_ROTATE_DURATION,
                ease: easeInOut,
            } : {},
            initial: { opacity: 0, zIndex: ZIndexes.landingPageAnim },
        },
        topOverlayTexture: {
            animate: INITIAL_ANIM_SHOW_DETAILS ? { opacity: [0, 1, 0.2, 0], translateX: c.top.t.initial, translateY: c.top.t.initial } : {},
            transition: INITIAL_ANIM_SHOW_DETAILS ? {
                duration: INITIAL_ANIM_SHOW_DETAILS ? SEPARATION_DURATION_LONG : SEPARATION_DURATION_SHORT,
                times: [0.2, 0.5, 0.7, 1],
                delay: APPEAR_AND_ROTATE_DURATION,
                ease: easeInOut,
            } : {},
            initial: { opacity: 0, zIndex: ZIndexes.landingPageAnim },
        },
        bottom: {
            animate: { translateX: c.bottom.t.initial, translateY: c.bottom.t.initial },
            transition: {
                duration: INITIAL_ANIM_SHOW_DETAILS ? SEPARATION_DURATION_LONG : SEPARATION_DURATION_SHORT,
                delay: APPEAR_AND_ROTATE_DURATION,
                ease: easeInOut,
            },
            initial: {},
        },
        bottomOverlay: {
            initial: { opacity: 0 },
        }
    }), []);


    // ANIMATIONS ON SCROLL ---------------------------------------------------------------------

    // watermark & image parent div
    const styleParent = {
        rotateX: c.parent.rotate.X,
        rotateY: 0,
        rotateZ: c.parent.rotate.Z,
        translateZ: 0,
        translateX: 0,
    }

    // top layer (watermark)
    const styleTop = {
        translateX: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGING], s[AnimStage.WMMERGED]],
            [c.top.t.initial, c.top.t.hover, c.top.t.hover, c.top.t.merged],
            { ease: easeInOut }
        ),
        translateY: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGING], s[AnimStage.WMMERGED]],
            [c.top.t.initial, c.top.t.hover, c.top.t.hover, c.top.t.merged],
            { ease: easeInOut }
        ),
        translateZ: 0,
        opacity: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGING], s[AnimStage.WMMERGED]],
            [0.5, 0.8, 0.8, 0],
            { ease: easeInOut }
        ),
        borderRadius: `${borderRadiusPx}px`,
    }
    const styleTopDetails = {
        ...styleTop,
        backgroundColor: 'none',
        opacity: useTransform(scrollYProgress,
            [
                s[AnimStage.INITIAL],
                getPointOnLine(s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], 1 / 2),
                s[AnimStage.WMDETAILS],
                s[AnimStage.WMMERGING],
                getPointOnLine(s[AnimStage.WMMERGING], s[AnimStage.WMMERGED], 1 / 2),
                s[AnimStage.WMMERGED]
            ],
            [0, 0.2, 1, 1, 0.2, 0],
            { ease: easeInOut }
        ),
    }
    const styleTopTexture = {
        ...styleTop,
        backgroundColor: 'none',
        opacity: useTransform(scrollYProgress,
            [
                s[AnimStage.INITIAL],
                getPointOnLine(s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], 1 / 2),
                s[AnimStage.WMDETAILS],
                s[AnimStage.WMMERGING],
                getPointOnLine(s[AnimStage.WMMERGING], s[AnimStage.WMMERGED], 1 / 2),
                s[AnimStage.WMMERGED]
            ],
            [0, 1, 0, 0, 1, 0],
            { ease: easeInOut }
        ),
    }

    // bottom layer (image)
    const styleBottom = {
        translateX: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGED]],
            [c.bottom.t.initial, c.bottom.t.initial, c.bottom.t.merged],
            { ease: easeInOut }
        ),
        translateY: useTransform(scrollYProgress,
            [s[AnimStage.INITIAL], s[AnimStage.WMDETAILS], s[AnimStage.WMMERGED]],
            [c.bottom.t.initial, c.bottom.t.initial, c.bottom.t.merged],
            { ease: easeInOut }
        ),
        translateZ: 0,
    }

    // RETURN ---------------------------------------------------------------------

    return (<>
        <Box
            position='absolute'
            right={`calc(${animRightMargin || '0px'} + ${animSizePx * 0.16}px)`}
            top={`calc(50% - ${animSizePx / 2}px)`}
            zIndex={ZIndexes.landingPageClickable}
        >

            {/* WATERMARK & IMAGE -------------- */}
            <motion.div
                className='testdiv'
                style={{
                    ...(initialAnimationComplete ? styleParent : {}),
                    width: `${animSizePx}px`,
                    height: `${animSizePx}px`,
                }}
                animate={initialAnimations.parent.animate}
                transition={initialAnimations.parent.transition}
                initial={initialAnimations.parent.initial}
            >

                {/* TOP LAYER -------------- */}
                <motion.div
                    className='top-layer top-layer-overlay top-layer-details'
                    style={initialAnimationComplete ? styleTopDetails : {}}
                    animate={initialAnimations.topOverlayDetails.animate}
                    transition={initialAnimations.topOverlayDetails.transition}
                    initial={initialAnimations.topOverlayDetails.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                >
                    <DetailsContent variant='details' animSizePx={animSizePx} borderRadiusPx={borderRadiusPx} />
                </motion.div>
                <motion.div
                    className='top-layer top-layer-overlay top-layer-texture'
                    style={initialAnimationComplete ? styleTopTexture : {}}
                    animate={initialAnimations.topOverlayTexture.animate}
                    transition={initialAnimations.topOverlayTexture.transition}
                    initial={initialAnimations.topOverlayTexture.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                >
                    <DetailsContent variant='texture' animSizePx={animSizePx} borderRadiusPx={borderRadiusPx} />
                </motion.div>
                <motion.div
                    className='top-layer'
                    style={initialAnimationComplete ? styleTop : { borderRadius: `${borderRadiusPx}px` }}
                    animate={initialAnimations.top.animate}
                    transition={initialAnimations.top.transition}
                    initial={initialAnimations.top.initial}
                    onAnimationComplete={setInitialAnimationComplete}
                // onHoverStart={() => console.log('hover start')}
                // onHoverEnd={() => console.log('hover end')}
                >
                </motion.div>

                {/* BOTTOM LAYER ------------- */}
                <motion.div
                    className='bottom-layer'
                    style={initialAnimationComplete ? styleBottom : {}}
                    animate={initialAnimations.bottom.animate}
                    transition={initialAnimations.bottom.transition}
                    initial={initialAnimations.bottom.initial}
                >
                    <Box component='img' src={image_square_2} width='100%' alt='image' sx={{ borderRadius: `${borderRadiusPx}px` }} />
                </motion.div>

            </motion.div>
        </Box>
    </>);
}
