import { ResponsiveFontSizes } from "@/components";
import { FontWeights } from "@/components/variables";
import { Box, SxProps } from "@mui/material";

type LpTextVariant = 'header' | 'headerBold' | 'headerLight' | 'subheader' | 'subheaderLight' | 'body' | 'bodyLight' | 'label';
type LpTextProps = {
    variant?: LpTextVariant,
    fontSize?: string | number,
    lineHeight?: number,
}
export const LpText = ({ variant = 'body', fontSize, lineHeight, children }: React.PropsWithChildren<LpTextProps>) => {
    const DEFAULT_LINE_HEIGHT = 1.1;
    const variantStyles: Record<LpTextVariant, SxProps> = {
        header: {
            fontSize: fontSize || ResponsiveFontSizes.header1,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        headerBold: {
            fontSize: fontSize || ResponsiveFontSizes.header1,
            fontWeight: FontWeights.semibold,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        headerLight: {
            fontSize: fontSize || ResponsiveFontSizes.header2,
            fontWeight: FontWeights.light,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        subheader: {
            fontSize: fontSize || ResponsiveFontSizes.header3,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        subheaderLight: {
            fontSize: fontSize || ResponsiveFontSizes.header3,
            fontWeight: FontWeights.light,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        body: {
            fontSize: fontSize || ResponsiveFontSizes.header4,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        bodyLight: {
            fontSize: fontSize || ResponsiveFontSizes.header4,
            fontWeight: FontWeights.light,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
        label: {
            fontSize: fontSize || ResponsiveFontSizes.body0,
            lineHeight: lineHeight || DEFAULT_LINE_HEIGHT,
        },
    }
    return (<Box sx={variantStyles[variant]}>{children}</Box>)
}