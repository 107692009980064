import { Box, BoxProps, SxProps } from "@mui/material"
import { MAX_CONTENT_WIDTH_PX } from "../types-and-constants"
import { Colors, ResponsiveSpacing } from "@/components"
import React from "react"

type SectionWrapperColorVariant = 'light' | 'gray' | 'dark' | 'accentDark' | 'accentLight';
export type SectionWrapperProps = BoxProps & {
    colorVariant?: SectionWrapperColorVariant,
    compact: boolean,
    marginLeftRight: string;
    outerBoxProps?: BoxProps;
}
export const SectionWrapper = ({ colorVariant, children, compact, marginLeftRight, outerBoxProps, ...muiBoxProps }: React.PropsWithChildren<SectionWrapperProps>) => {
    const colorVariants: Record<SectionWrapperColorVariant, SxProps> = {
        light: {
            backgroundColor: Colors.offWhiteDarker
        },
        gray: {
            backgroundColor: Colors.lightestGray
        },
        dark: {
            backgroundColor: Colors.dark, color: 'white'
        },
        accentDark: {
            backgroundColor: Colors.accentDarkLp, color: 'white'
        },
        accentLight: {
            backgroundColor: Colors.accentLightLp,
        }
    }
    return (
        <Box
            paddingTop={ResponsiveSpacing.space7} paddingBottom={ResponsiveSpacing.space7}
            paddingLeft={marginLeftRight} paddingRight={marginLeftRight}
            boxSizing='border-box' width='100%'
            display='flex' justifyContent='center'
            {...outerBoxProps}
            sx={{ ...colorVariants[colorVariant || 'light'], ...outerBoxProps?.sx } as SxProps}
        >
            <Box
                width='100%' maxWidth={`${MAX_CONTENT_WIDTH_PX}px`}
                display='flex' flexDirection='column' justifyContent='center' alignItems='center'
                {...muiBoxProps}
                sx={muiBoxProps?.sx}
            >
                {children}
            </Box>
        </Box>
    )
}