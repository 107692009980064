import { Box } from "@mui/material"
import { DescriptionTextBlock, LpText, SectionWrapper, SectionWrapperProps } from "./../components"
import { ResponsiveSpacing } from "@/components";

export interface SectionIntegrationsProps {
    marginLeftRight: string;
    compact: boolean;
}
export const SectionIntegrations = ({ marginLeftRight, compact }: SectionIntegrationsProps) => {
    const sectionWrapperProps: SectionWrapperProps = { marginLeftRight, compact }
    const contentPlaceholder = (
        <Box flex={1} border='1px solid black' height='400px'>
            <LpText variant='body'>content placeholder</LpText>
        </Box>
    )
    return (
        <SectionWrapper {...sectionWrapperProps} flexDirection='row' gap={ResponsiveSpacing.space6}>
            <DescriptionTextBlock
                headerTop="INTEGRATIONS"
                headerBottom="Available within your workflow"
                description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                flex={1}
                alignSelf='center'
            />
            {contentPlaceholder}
        </SectionWrapper>
    )
}