import './styles.css';
import { MotionValue } from 'framer-motion';
import Anim from './anim';

interface WatermarkAnimProps {
    animSizePx: number;
    animRightMargin?: string,
    scrollYProgress: MotionValue<number>;
    initialAnimationComplete: boolean;
    onInitialAnimationCompleteChange: (newVal: boolean) => void;
}
export default function WatermarkAnim({ animSizePx, animRightMargin, scrollYProgress, initialAnimationComplete, onInitialAnimationCompleteChange }: WatermarkAnimProps) {

    return (
        <>
            {/* main animation */}
            <Anim
                scrollYProgress={scrollYProgress}
                initialAnimationComplete={initialAnimationComplete}
                setInitialAnimationComplete={() => onInitialAnimationCompleteChange(true)}
                animSizePx={animSizePx}
                animRightMargin={animRightMargin}
            />
        </>
    );
}
