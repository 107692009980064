import { Box } from "@mui/material"
import { LpText, SectionWrapper, SectionWrapperProps } from "./../components"
import { ResponsiveSpacing } from "@/components";

export interface SectionTrustProps {
    marginLeftRight: string;
    compact: boolean;
}

export const SectionTrust = ({ marginLeftRight, compact }: SectionTrustProps) => {
    const sectionWrapperProps: SectionWrapperProps = { marginLeftRight, compact }
    return (
        <SectionWrapper
            colorVariant="accentLight"
            {...sectionWrapperProps}
            gap={ResponsiveSpacing.space6}
        >
            <LpText variant='headerBold'>
                Trusted by organizations around the world
            </LpText>

            <Box
                display='flex'
                justifyContent='center' alignItems='center'
                gap={ResponsiveSpacing.space4}
            >
                <Box flex={1}>
                    <LpText variant='header'>3 Million+</LpText>
                    <LpText>images watermarked</LpText>
                </Box>
                <Box flex={1.3}>
                    <LpText variant='body'>Leading contributor to</LpText>
                    <LpText variant='header'>AISIC, C2PA</LpText>
                    <LpText>Trufo was recently invited to teach other experts about content authenticity. [Watch]</LpText>
                </Box>
                <Box flex={1.8}>
                    <LpText variant='body'>Founding team from</LpText>
                    <LpText variant='header'>Princeton + Stanford</LpText>
                    <LpText variant='header'>Google + Netflix</LpText>
                </Box>
                <Box flex={1}>
                    <LpText variant='body'>R&D appearances at</LpText>
                    <LpText variant='header'>NeurIPS '24</LpText>
                    <LpText variant='header'>RWC '25</LpText>
                </Box>
            </Box>

        </SectionWrapper>
    )
}