import { Box, BoxProps } from "@mui/material"
import { LpText } from "./lp-text"
import { ResponsiveSpacing } from "@/components";

type DescriptionTextBlockAlignment = 'left' | 'center' | 'right';
export type DescriptionTextBlockProps = BoxProps & {
    headerTop?: string,
    headerBottom?: string,
    description?: string
    align?: DescriptionTextBlockAlignment
}

export const DescriptionTextBlock = ({ headerTop, headerBottom, description, align = 'left', ...boxProps }: DescriptionTextBlockProps) => {
    const flexAlignment: Record<DescriptionTextBlockAlignment, 'start' | 'center' | 'end'> = {
        left: 'start',
        center: 'center',
        right: 'end'
    }
    return (
        <Box
            display='flex' flexDirection='column'
            alignSelf={flexAlignment[align]}
            textAlign={flexAlignment[align]}
            gap={ResponsiveSpacing.space3}
            {...boxProps}
        >
            {headerTop &&
                <LpText variant='subheader'>{headerTop?.toLocaleUpperCase()}</LpText>
            }
            {headerBottom &&
                <LpText variant='headerBold'>{headerBottom}</LpText>
            }
            {description &&
                <LpText variant='subheader'>{description}</LpText>
            }
        </Box>
    )

}