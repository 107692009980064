import { Box } from "@mui/material"
import { FontWeights, ResponsiveSpacing } from "@/components"
import { DescriptionTextBlock, LpButton, LpText, SectionWrapper, SectionWrapperProps } from "./../components"

export interface SectionValueProps {
    marginLeftRight: string;
    compact: boolean;
}
export const SectionValue = ({ marginLeftRight, compact }: SectionValueProps) => {
    const sectionWrapperProps: SectionWrapperProps = { marginLeftRight, compact }
    const gridPlaceholder = (
        <Box height='400px' width='100%' sx={{ backgroundColor: 'white' }}>
            <LpText variant='body'>grid placeholder</LpText>
        </Box>
    )
    return (
        <SectionWrapper {...sectionWrapperProps} gap={ResponsiveSpacing.space5}>
            <DescriptionTextBlock
                headerTop="INVEST IN YOUR CONTENT"
                headerBottom="Content Labels Are Valuable"
                align='left'
            />
            {gridPlaceholder}
            <Box alignSelf='flex-end'>
                <LpButton
                    variant='secondary' sizeVariant='large' shapeVariant='round'
                    sx={{ fontWeight: FontWeights.regular }}
                >
                    Discover Use Cases
                </LpButton>
            </Box>
        </SectionWrapper>
    )
}