import { Box } from "@mui/material"
import { DescriptionTextBlock, LpButton, LpText, SectionWrapper, SectionWrapperProps } from "./../components"
import { Colors, ResponsiveSpacing } from "@/components";

export interface SectionUseCasesProps {
    marginLeftRight: string;
    compact: boolean;
}
export const SectionUseCases = ({ marginLeftRight, compact }: SectionUseCasesProps) => {
    const sectionWrapperProps: SectionWrapperProps = { marginLeftRight, compact }
    const contentPlaceholder = (
        <Box flex={2} height='400px' sx={{ backgroundColor: Colors.offWhite }}>
            <LpText variant='body'>grid placeholder</LpText>
        </Box>
    )
    return (
        <SectionWrapper
            {...sectionWrapperProps} flexDirection='row' gap={ResponsiveSpacing.space6}
            colorVariant="gray"
        >
            {contentPlaceholder}
            <Box flex={1}>
                <DescriptionTextBlock
                    headerBottom="Build a solution that suits your use case"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    alignSelf='center'
                    marginBottom={ResponsiveSpacing.space6}
                />
                <LpButton colorVariant='accentDark'>Request a demo</LpButton>
            </Box>
        </SectionWrapper>
    )
}