import { Box } from "@mui/material"
import { getPageMarginLeftRight, MAX_CONTENT_WIDTH_PX, SCROLL_POSITIONS } from "../types-and-constants"
import { Colors, FontWeights, ResponsiveFontSizes, ResponsiveSpacing, ZIndexes } from "@/components"
import { easeInOut, motion, MotionValue, useTransform } from "framer-motion"
import WatermarkAnim from "../watermark-anim"
import { LpButton } from "./../components"

export interface SectionLandingProps {
    animSizePx?: number,
    pageWidthPx?: number,
    pageHeightPx?: number,
    scrollYProgress: MotionValue<number>;
    initialAnimationComplete: boolean;
    onInitialAnimationCompleteChange: (newVal: boolean) => void;
    compact: boolean;
}
export const SectionLanding = ({ animSizePx, pageWidthPx = 0, pageHeightPx = 0, scrollYProgress, initialAnimationComplete, onInitialAnimationCompleteChange, compact }: SectionLandingProps) => {
    // const [watermarkAnimContainer, animateWatermarkAnimContainer] = useAnimate();
    const scrollPointY = (pageHeightPx + 20) * -1; // TODO: find the correct scroll point instead of using the random 36 constant to adjust
    const translateY = useTransform(scrollYProgress, [0, SCROLL_POSITIONS['scroll'], 1], [0, 0, scrollPointY]);
    const pageWidthPxToUse = pageWidthPx > MAX_CONTENT_WIDTH_PX ? MAX_CONTENT_WIDTH_PX : pageWidthPx;
    const marginLeftRight = getPageMarginLeftRight(compact, pageWidthPx);
    return (
        <Box>
            {/* Landing section text */}
            <motion.div
                style={{
                    position: 'fixed',
                    translateY: translateY,
                    zIndex: ZIndexes.landingPageClickable
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 1] }}
                transition={{
                    duration: 1,
                    ease: easeInOut,
                    times: [0, 1]
                }}
            >
                <Box
                    width={`calc(${pageWidthPxToUse * 0.48}px)`} maxWidth={`${MAX_CONTENT_WIDTH_PX}px`} height='100vh'
                    display='flex' flexDirection='column' justifyContent='center' gap='2em'
                    textAlign='left' color={Colors.white}
                    marginLeft={marginLeftRight}
                >
                    <Box fontSize={ResponsiveFontSizes.header0} lineHeight='90%'>Embed Trust Into Your Digital Content</Box>
                    <Box fontSize={ResponsiveFontSizes.header3}>Label your content—images, video, and audio—with secure information that sticks. Something about C2PA and more and the sentence is probably around this length.</Box>
                    <LpButton
                        variant='secondary'
                        colorVariant='light'
                        shapeVariant='round'
                        sizeVariant='large'
                        sx={{ alignSelf: 'start', fontWeight: FontWeights.regular }}
                    >
                        Get Started Now
                    </LpButton>
                </Box>
            </motion.div>

            {/* Watermark animation */}
            <motion.div
                // ref={watermarkAnimContainer}
                style={{
                    width: '100%', height: '100%',
                    position: 'fixed', translateY: translateY
                }}
            >
                {animSizePx &&
                    <WatermarkAnim
                        animSizePx={animSizePx}
                        animRightMargin={marginLeftRight}
                        scrollYProgress={scrollYProgress}
                        initialAnimationComplete={initialAnimationComplete}
                        onInitialAnimationCompleteChange={onInitialAnimationCompleteChange}
                    />
                }
            </motion.div>

            {/* Scroll down to learn more text (appears only after the initial animation is complete) */}
            {initialAnimationComplete &&
                <motion.div
                    style={{
                        position: 'fixed',
                        bottom: ResponsiveSpacing.space5,
                        fontWeight: FontWeights.light,
                        fontSize: ResponsiveFontSizes.body0,
                        width: '100%',
                        color: Colors.accentLightLp,
                        translateY: translateY,
                        zIndex: ZIndexes.landingPageClickable
                    }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: [0, 1] }}
                    transition={{
                        duration: 0.3,
                        ease: easeInOut,
                        times: [0, 1]
                    }}
                >
                    <Box>SCROLL DOWN TO LEARN MORE</Box>
                    <Box marginTop={`-10px`}>⌄</Box>
                </motion.div>}
        </Box >
    )
}