import { Box } from "@mui/material"
import { LpButton, LpText, SectionWrapper, SectionWrapperProps } from "./../components"
import c2pa_logo from '@assets/logo_c2pa.png'
import { Colors, ResponsiveBorderWeights, responsiveSize, ResponsiveSpacing, TrufoButton } from "@/components";

export interface SectionFreeProductsProps {
    marginLeftRight: string;
    compact: boolean;
}

export const SectionFreeProducts = ({ marginLeftRight, compact }: SectionFreeProductsProps) => {
    const sectionWrapperProps: SectionWrapperProps = { marginLeftRight, compact }
    const getProductBox = (labelTop?: string, labelBottom?: string, image?: string) => (
        <Box
            border={`${ResponsiveBorderWeights.weight1} solid white`}
            borderRadius={ResponsiveSpacing.space0}
            width={responsiveSize(28)} height={responsiveSize(28)}
            padding={ResponsiveSpacing.space5} boxSizing='border-box'
            display='flex' flexDirection='column' justifyContent='center' alignItems='center'
            gap={ResponsiveSpacing.space4}
        >
            {image && <Box component='img' src={image} alt={image} height='auto' width={responsiveSize(20)} />}
            <Box display='flex' flexDirection='column' gap={ResponsiveSpacing.space1}>
                {labelTop && <LpText variant='subheaderLight'>{labelTop}</LpText>}
                {labelBottom && <LpText variant='headerLight' lineHeight={0.9}>{labelBottom}</LpText>}
            </Box>
        </Box>
    )

    return (
        <SectionWrapper {...sectionWrapperProps} outerBoxProps={{ paddingTop: 0, paddingBottom: 0 }}>
            <SectionWrapper
                colorVariant="accentDark"
                {...sectionWrapperProps}
                gap={ResponsiveSpacing.space6}
            >
                <LpText variant='headerBold'>
                    As part of our commitment to content authenticity, essential features are free:
                </LpText>
                <Box display='flex' gap={ResponsiveSpacing.space6}>
                    <Box display='flex' gap={ResponsiveSpacing.space4}>
                        {getProductBox('One-Click', 'C2PA Integration', c2pa_logo)}
                        {getProductBox('Durable', 'Invisible Watermarking', c2pa_logo)}
                        {getProductBox(undefined, 'Compliance Tagging', c2pa_logo)}
                    </Box>
                </Box>
                <Box
                    display='flex'
                    gap={ResponsiveSpacing.space4} justifyContent='center'
                >
                    <LpButton colorVariant='light' sx={{ color: Colors.accentDarkLp }}>Get Started</LpButton>
                    <LpButton colorVariant='light' variant='secondary'>Premium Features</LpButton>
                </Box>
            </SectionWrapper>
        </SectionWrapper >
    )
}