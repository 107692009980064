import { Colors, CompactMenu, FontWeights, NAVBAR_HEIGHT_PX, ResponsiveSpacing, TrufoButton, TrufoLink, ZIndexes } from "@/components"
import { Box } from "@mui/material"
import trufo_white from '@assets/trufo_white.png';
import { BLOG_LINK, CONTACT_US_EMAIL, DISCORD_LINK, LOGIN_ROUTE, MAIN_APP_ROUTE, PRIVACY_POLICY_ROUTE, TERMS_OF_SERVICE_ROUTE, X_LINK } from "@/app-routes";
import { motion } from "framer-motion";
import MenuIcon from '@mui/icons-material/Menu';

export interface LpNavbarProps {
    compact?: boolean;
    loggedIn?: boolean;
    marginLeftRight?: string;
}
export const LpNavbar = ({ compact, loggedIn, marginLeftRight }: LpNavbarProps) => {

    const COMPACT_MENU_BUTTON_ICON_PX = 24;
    const COMPACT_MENU_BUTTON_PADDING_PX = 8;
    const COMPACT_MENU_BUTTON_DIMENSION_PX = COMPACT_MENU_BUTTON_ICON_PX + COMPACT_MENU_BUTTON_PADDING_PX * 2;

    const topLinks: Record<string, string> = {
        'blog': BLOG_LINK,
        'discord': DISCORD_LINK,
        'x/twitter': X_LINK,
        'contact us': CONTACT_US_EMAIL,
    };
    const bottomLinks: Record<string, string> = {
        'privacy policy': PRIVACY_POLICY_ROUTE,
        'terms of service': TERMS_OF_SERVICE_ROUTE,
    }

    const getNavbarItem = (name: string, href: string) => (
        <TrufoLink
            href={href} trufoVariant="white"
            noUnderline newtab
            colorOnHover={Colors.accentLightLp}
        >
            {name}
        </TrufoLink>
    )

    return (
        <Box
            position='fixed' left='0' top='0' width='100dvw' height={NAVBAR_HEIGHT_PX}
            boxSizing='border-box'
            paddingLeft={marginLeftRight || ResponsiveSpacing.space6} paddingRight={marginLeftRight || ResponsiveSpacing.space6}
            // flex={0} flexBasis={NAVBAR_HEIGHT_PX}
            display='flex' justifyContent='space-between' alignItems='center'
            borderBottom={`1px solid ${Colors.white}`}
            zIndex={ZIndexes.landingPageNavbar}
            sx={{ backgroundColor: Colors.dark }}
        >
            <Box component='img' src={trufo_white} height='50px' alt='trufo' />
            {!compact ?
                <>
                    <Box
                        display='flex' justifyContent='center' alignItems='center' gap='20px'
                        zIndex={ZIndexes.landingPageClickable} color='white'
                    >
                        <Box>{getNavbarItem('products', '/')}</Box>
                        <Box>{getNavbarItem('publications', '/')}</Box>
                        <Box>{getNavbarItem('people', '/')}</Box>
                    </Box>
                    <Box display='flex' justifyContent='flex-end' alignItems='center' gap='20px' zIndex={ZIndexes.landingPageClickable}>
                        <Box>{getNavbarItem('Log In', '/login')}</Box>
                        <TrufoButton
                            colorVariant='landingPagePrimaryLight' label='Request a demo'
                            sx={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: FontWeights.bold }}
                        />
                    </Box>
                </>
                : <CompactMenu
                    links={{
                        ...topLinks,
                        ...bottomLinks,
                        ...(loggedIn ? { 'go to app': MAIN_APP_ROUTE, } : { 'log in': LOGIN_ROUTE, })
                    }}
                    containerProps={{
                        position: 'absolute',
                        right: `calc(${marginLeftRight} - ${COMPACT_MENU_BUTTON_PADDING_PX}px)`,
                        top: `${(NAVBAR_HEIGHT_PX - COMPACT_MENU_BUTTON_DIMENSION_PX) / 2}`,
                        zIndex: ZIndexes.navbar,
                        visibility: compact ? 'visible' : 'hidden'
                        // visibility: (compact && !(extraCompact && onDetailPage)) ? 'visible' : 'hidden'
                    }}
                    buttonProps={{
                        sx: {
                            paddingLeft: 0, paddingRight: 0,
                            paddingTop: `${COMPACT_MENU_BUTTON_PADDING_PX}px`,
                            paddingBottom: `${COMPACT_MENU_BUTTON_PADDING_PX}px`,
                            width: `${COMPACT_MENU_BUTTON_DIMENSION_PX}px !important`,
                            minWidth: `${COMPACT_MENU_BUTTON_DIMENSION_PX}px !important`
                        }
                    }}
                >
                    <motion.div
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <MenuIcon />
                    </motion.div>
                </CompactMenu>
            }
        </Box >
    )
}