import { Box } from '@mui/material';
import image_square_1 from '@assets/image_square_1.jpg';
import { ImageAspectRatio, ImageBase64 } from '@types';
import { ImageAlignment, MAX_LENGTHS } from '.';
import { getImageAspectRatioStr, getImageUrl } from '@/utils';
import { Colors, Fonts, ResponsiveFontSizes, horizontallyCenteredAbsoluteStyle } from '@/components';

type PreviewProps = {
    image?: File | ImageBase64,
    imageAspectRatio?: ImageAspectRatio,
    imageAlignment: ImageAlignment,
    isPreview?: boolean,
};

export default function ImageDisplay({ image, imageAlignment, isPreview, imageAspectRatio }: PreviewProps) {

    return image ? (
        <Box
            position='relative'
            maxHeight={imageAlignment === 'vertical' ? MAX_LENGTHS.shorter : MAX_LENGTHS.longer}
            maxWidth={imageAlignment === 'vertical' ? MAX_LENGTHS.longer : MAX_LENGTHS.shorter}
            sx={{ aspectRatio: getImageAspectRatioStr(imageAspectRatio) }}
        >
            {isPreview &&
                <Box
                    top='2%'
                    color={Colors.white} fontFamily={Fonts.monospace} fontSize={ResponsiveFontSizes.header3}
                    sx={{ textShadow: '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000', ...horizontallyCenteredAbsoluteStyle }}
                >
                    PREVIEW
                </Box>
            }
            <Box
                display='block' width='100%' height='100%'
                component='img' src={getImageUrl(image) || image_square_1}
            // sx={{ ...(isPreview ? previewImageStyle : {}) }}
            />
        </Box>
    ) : null;
}