import { ResponsiveFontSizes, TrufoButton, TrufoButtonColorVariant, TrufoButtonProps } from "@/components";
import { ResponsiveSpacing, ZIndexes } from "@/components/variables";
import { SxProps } from "@mui/material";

type LpButtonVariant = 'primary' | 'secondary';
type LpButtonColorVariant = 'light' | 'dark' | 'accentDark';
type LpButtonSizeVariant = 'large' | 'medium' | 'small';
type LpButtonShapeVariant = 'square' | 'round';
export type LpButtonProps = {
    variant?: LpButtonVariant,
    colorVariant?: LpButtonColorVariant,
    sizeVariant?: LpButtonSizeVariant,
    shapeVariant?: LpButtonShapeVariant,
    sx?: SxProps,
}

export const LpButton = ({ variant = 'primary', colorVariant = 'dark', sizeVariant = 'medium', shapeVariant = 'square', sx, children }: React.PropsWithChildren<LpButtonProps>) => {
    const getTfButtonColorVariant = (): TrufoButtonColorVariant => {
        switch (colorVariant) {
            case 'light':
                return variant === 'primary' ? 'landingPagePrimaryLight' : 'landingPageSecondaryLight';
            case 'dark':
                return variant === 'primary' ? 'landingPagePrimaryDark' : 'landingPageSecondaryDark';
            case 'accentDark':
                return variant === 'primary' ? 'landingPagePrimaryAccentDark' : 'landingPageSecondaryAccentDark';
        }
    }
    const sizeVariantStyles: Record<LpButtonSizeVariant, TrufoButtonProps> = {
        large: {
            sx: {
                fontSize: ResponsiveFontSizes.header3,
                paddingTop: ResponsiveSpacing.space4, paddingBottom: ResponsiveSpacing.space4,
                paddingLeft: ResponsiveSpacing.space5, paddingRight: ResponsiveSpacing.space5,
                borderRadius: shapeVariant === 'round' ? ResponsiveFontSizes.header2 : ResponsiveFontSizes.body2,
            }
        },
        medium: {
            sx: {
                fontSize: ResponsiveFontSizes.header4,
                paddingTop: ResponsiveSpacing.space3, paddingBottom: ResponsiveSpacing.space3,
                paddingLeft: ResponsiveSpacing.space4, paddingRight: ResponsiveSpacing.space4,
                borderRadius: shapeVariant === 'round' ? ResponsiveFontSizes.header3 : ResponsiveFontSizes.body3,
            }
        },
        small: {
            sx: {
                fontSize: ResponsiveFontSizes.body0,
                paddingTop: ResponsiveSpacing.space2, paddingBottom: ResponsiveSpacing.space2,
                paddingLeft: ResponsiveSpacing.space3, paddingRight: ResponsiveSpacing.space3,
                borderRadius: shapeVariant === 'round' ? ResponsiveFontSizes.header4 : ResponsiveFontSizes.body4,
            }
        }
    }

    return (
        <TrufoButton
            colorVariant={getTfButtonColorVariant()}
            sx={{
                ...sizeVariantStyles[sizeVariant]?.sx,
                ...sx,
                zIndex: ZIndexes.landingPageClickable,
            } as SxProps}
        >
            {children}
        </TrufoButton>
    )
}