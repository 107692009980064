import { Box } from "@mui/material";
import { COMPACT_VIEW_WIDTH_PX, EXTRA_COMPACT_VIEW_WIDTH_PX, getPageMarginLeftRight, MAX_ANIM_SIZE_PX, MAX_CONTENT_WIDTH_PX, MIN_ANIM_SIZE_PX, SCROLL_DIV_HEIGHT } from "./types-and-constants";
import { Colors, LpNavbar, NAVBAR_HEIGHT_PX } from "@components";
import { useEffect, useRef, useState } from "react";
import { motion, useScroll } from 'framer-motion';
import { useGetUser } from "@/hooks";
import { SectionFreeProducts, SectionLanding, SectionProducts, SectionTrust, SectionUseCases, SectionValue } from "./sections";
import { SectionIntegrations } from "./sections/section-integrations";
import { LpFooter } from "./components";

type LandingPagePages = 'details' | 'home';
interface LandingPageProps {
    page?: LandingPagePages,
}

export default function LandingPage({ page }: LandingPageProps) {
    const pageContainer = useRef<HTMLElement>(null);
    const animContainer = useRef<HTMLElement>(null);
    const [animSizePx, setAnimSizePx] = useState<number>();
    const [pageWidthPx, setPageWidthPx] = useState<number>();
    const [pageHeightPx, setPageHeightPx] = useState<number>();
    const [compact, setCompact] = useState<boolean>(false); // right column dissapears and becomes a menu button
    const [extraCompact, setExtraCompact] = useState<boolean>(false); // logo + menu button dissapear in detail page
    const [scrollTop, setScrollTop] = useState<number>(0);
    // const [onDetailPage, setOnDetailPage] = useState<boolean>(page === 'details' ? true : false);
    const [compactViewEvaluated, setCompactViewEvaluated] = useState<boolean>(false);
    const sectionLandingContainer = useRef<HTMLDivElement>(null);
    const { scrollYProgress: scrollYProgressLanding } = useScroll({ target: sectionLandingContainer, offset: ["start start", "end start"] });
    const [initialAnimationComplete, setInitialAnimationComplete] = useState<boolean>(false);

    const marginLeftRight = getPageMarginLeftRight(compact, pageWidthPx);

    const { getUser: getTrufoUser, data: trufoUserData, error: getTrufoUserError } = useGetUser();
    useEffect(() => { getTrufoUser() }, [getTrufoUser])
    const loggedIn = trufoUserData && !getTrufoUserError;

    // set animSizePx (animation size) based on anim container height and width
    useEffect(() => {
        if (!pageContainer.current) return;
        const resizeObserver = new ResizeObserver((elementArr) => {
            // Whenever the size of the element changes:
            const element = elementArr?.[0]?.borderBoxSize?.[0];
            const height = element?.blockSize;
            const width = element?.inlineSize;
            const pageWidth = pageContainer?.current?.offsetWidth;
            // TODO: check if this is correct
            const pageHeight = pageContainer?.current?.offsetHeight;
            pageWidth && setPageWidthPx(pageWidth);
            pageHeight && setPageHeightPx(pageHeight);
            if (height && width) {
                // set anim size
                const newSizeFromHeight = (height - NAVBAR_HEIGHT_PX) * 0.55
                const newSizeFromWidth = Math.min(width, MAX_CONTENT_WIDTH_PX) * 0.32
                const newSize = Math.max(Math.min(newSizeFromHeight, newSizeFromWidth), MIN_ANIM_SIZE_PX)
                compactViewEvaluated && setAnimSizePx(newSize); // don't let anim be initialized until proper width is set by compact view

                // set compact
                if (pageWidth && pageWidth > COMPACT_VIEW_WIDTH_PX) {
                    setCompact(false);
                    setExtraCompact(false);
                } else {
                    setCompact(true);
                    if (pageWidth && pageWidth < EXTRA_COMPACT_VIEW_WIDTH_PX) {
                        setExtraCompact(true);
                    } else {
                        setExtraCompact(false);
                    }
                }
                setCompactViewEvaluated(true);
            }
        });
        resizeObserver.observe(pageContainer.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, [compactViewEvaluated]);


    // Track scroll position and during initial animation, scroll to top whenever someone scrolls down (hack because can't set overflowY to hidden)
    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollTop(e?.target?.documentElement?.scrollTop);
        };
        window.addEventListener("scroll", onScroll);
    }, [scrollTop]);
    useEffect(() => { if (!initialAnimationComplete && scrollTop !== 0) window.scrollTo(0, 0) }, [scrollTop, initialAnimationComplete])

    const sectionProps = { marginLeftRight, compact }

    return (
        <>
            <Box
                width='100dvw' height={`calc(100dvh - ${NAVBAR_HEIGHT_PX}px)`}
                paddingTop={`${NAVBAR_HEIGHT_PX / 2}px`}
                display='flex' flexDirection='column'
                ref={pageContainer}
            // sx={{ overflowY: initialAnimationComplete ? 'scroll' : 'hidden' }}
            >
                <LpNavbar compact={compact} loggedIn={loggedIn} marginLeftRight={marginLeftRight} />
                <Box
                    display='flex' flexDirection='column'
                >
                    <motion.div
                        style={{ height: SCROLL_DIV_HEIGHT, backgroundColor: Colors.dark }}
                        ref={sectionLandingContainer}
                    >
                        <SectionLanding
                            animSizePx={animSizePx}
                            pageHeightPx={pageHeightPx} pageWidthPx={pageWidthPx}
                            scrollYProgress={scrollYProgressLanding}
                            initialAnimationComplete={initialAnimationComplete}
                            onInitialAnimationCompleteChange={setInitialAnimationComplete}
                            compact={compact}
                        />
                    </motion.div>
                    {initialAnimationComplete ?
                        <>
                            <SectionValue {...sectionProps} />
                            <SectionProducts {...sectionProps} />
                            <SectionFreeProducts {...sectionProps} />
                            <SectionIntegrations {...sectionProps} />
                            <SectionTrust {...sectionProps} />
                            <SectionUseCases {...sectionProps} />
                            <LpFooter {...sectionProps} />
                        </>
                        : null
                    }
                </Box>
            </Box>
        </>
    )
}