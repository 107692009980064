import { Box } from "@mui/material"
import { DescriptionTextBlock, DescriptionTextBlockProps, LpText, SectionWrapper, SectionWrapperProps } from "./../components"
import { ResponsiveSpacing } from "@/components";

export interface SectionProductsProps {
    marginLeftRight: string;
    compact: boolean;
}
export const SectionProducts = ({ marginLeftRight, compact }: SectionProductsProps) => {
    const sectionWrapperProps: SectionWrapperProps = {
        marginLeftRight,
        compact,
        flexDirection: 'row',
        gap: ResponsiveSpacing.space8
    }
    const descriptionTextBlockProps: DescriptionTextBlockProps = {
        flex: 1,
        alignSelf: 'center'
    }
    const contentPlaceholder = (
        <Box flex={1} border='1px solid black' height='300px'>
            <LpText variant='body'>content placeholder</LpText>
        </Box>
    )
    return (
        <>
            <SectionWrapper {...sectionWrapperProps} colorVariant='gray'>
                <DescriptionTextBlock
                    headerTop="DURABLE LABELS"
                    headerBottom="State-of-the-art invisible watermarking for label retrieval"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    {...descriptionTextBlockProps}
                />
                {contentPlaceholder}
            </SectionWrapper>
            <SectionWrapper {...sectionWrapperProps}>
                {contentPlaceholder}
                <DescriptionTextBlock
                    headerTop="SECURE LABELS"
                    headerBottom="Tamper-aware, not just tamper-proof"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    {...descriptionTextBlockProps}
                />
            </SectionWrapper>
            <SectionWrapper {...sectionWrapperProps} colorVariant='gray'>
                <DescriptionTextBlock
                    headerTop="INTEROPERABLE LABELS"
                    headerBottom="Built on standards recognized by other platforms"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    {...descriptionTextBlockProps}
                />
                {contentPlaceholder}
            </SectionWrapper>
            <SectionWrapper {...sectionWrapperProps}>
                {contentPlaceholder}
                <DescriptionTextBlock
                    headerTop="CERTIFIED LABELS"
                    headerBottom="A blue checkmark for content"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna."
                    {...descriptionTextBlockProps}
                />
            </SectionWrapper>
        </>
    )
}