import { Colors, FontWeights, ResponsiveSpacing, TrufoLink } from "@/components"
import { Box } from "@mui/material"
import trufo_white from '@assets/trufo_white.png';
import { SectionWrapper, SectionWrapperProps } from "./section-wrapper";
import { CONTACT_US_EMAIL, PRIVACY_POLICY_ROUTE, TERMS_OF_SERVICE_ROUTE, VISION_LINK } from "@/app-routes";

export interface LpFooterProps {
    compact: boolean;
    marginLeftRight: string;
}
export const LpFooter = ({ compact, marginLeftRight }: LpFooterProps) => {
    const wrapperProps: SectionWrapperProps = { compact, marginLeftRight }

    const getLinkSection = (header: string, links: Record<string, string>) => (
        <Box display='flex' flexDirection='column' gap='20px' alignItems='start'>
            <Box fontWeight={FontWeights.bold}>{header}</Box>
            <Box display='flex' flexDirection='column' gap='2px' alignItems='start'>
                {Object.keys(links).map(linkName => (
                    <TrufoLink
                        href={links[linkName]} trufoVariant="white"
                        noUnderline newtab
                        colorOnHover={Colors.accentLightLp}
                    >
                        {linkName}
                    </TrufoLink>
                ))}
            </Box>
        </Box>
    )

    return (
        <SectionWrapper
            {...wrapperProps} colorVariant="dark"
            flexDirection='row' justifyContent='space-between'
        >
            <Box
                display='flex' flexDirection='column' alignItems='start' gap='20px'
            >
                <Box component='img' src={trufo_white} height='50px' alt='trufo' />
                <Box>socials</Box>
                <Box> © 2024 Trufo Inc.</Box>
            </Box>
            <Box display='flex' gap={ResponsiveSpacing.space6}>
                {getLinkSection('COMPANY', {
                    'About Us': VISION_LINK,
                    'Contact Us': CONTACT_US_EMAIL,
                    'Careers': '/',
                    'People': '/',
                    'Press': '/'
                })}
                {getLinkSection('TECHNOLOGY', {
                    'C2PA': '/',
                    'Watermark': '/',
                    'DiffDetect': '/',
                })}
                {getLinkSection('RESOURCES', {
                    'Blog': '/',
                    'Documentation': '/',
                    'Discord': '/',
                    'Whitepaper': '/',
                    'Publications': '/'
                })}
                {getLinkSection('LEGAL', {
                    'Terms of Service': TERMS_OF_SERVICE_ROUTE,
                    'Privacy Policy': PRIVACY_POLICY_ROUTE,
                    'CP/CPS': '/'
                })}
            </Box>
        </SectionWrapper >
    )
}